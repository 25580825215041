<template>
  <LiefengContent  @backClick="clickBar" isBack="true" :navList="navList" @clickBar="clickBar">
      <template #title>{{titleName}}</template>
      <template #toolsbarRight v-if="navList.length != 4">
      <Select
        v-model="selectValue"
        style="width: 200px"
        @on-select="changeSelect"
      >
        <Option
          v-for="(item, index) in selectList"
          :value="item.value"
          :key="index"
          >{{ item.label }}</Option
        >
      </Select>
    </template>
      <template #contentArea>

        <!-- 区 -->
        <Card class="card-margin" v-if="navList.length == 2">
          <TitleColor :title='titleName'>
              <template #titleRight>
                  {{$core.formatDate(new Date(),'yyyy-MM-dd '+'最新评估结果')}}
              </template>
          </TitleColor>
          <div class="stree-div">
            <div class="left">
              <Ring :data="schedule" ></Ring>
            </div>
            <div class="right" >
              <!-- 社区数 2021年12月17日最新评估 -->
              <VeTable :tableData="scheduleData" :height='400' :tableColumn="scheduleColumn" ></VeTable>
            </div>
          </div>
        </Card>

        <Card class="card-margin"  v-if="navList.length == 2">
          <TitleColor title='街道各社区建设进度情况'></TitleColor>
          <VeBar :showSelect="false" :data='streetBuild' :extend='streetBuildExtend'></VeBar>
          
        </Card>


        <!-- 街道 -->
        <Card class="card-margin" v-if="navList.length == 3">
          <TitleColor :title='titleName'>
              <template #titleRight>
                  {{$core.formatDate(new Date(),'yyyy-MM-dd '+'最新评估结果')}}
              </template>
          </TitleColor>
          <div class="stree-div">
            <div class="left">
              <Ring :data="scheduleo" ></Ring>
            </div>
            <div class="right" >
              <!-- 社区数 2021年12月17日最新评估 -->
              <VeTable :showSelect="false" :height='400' :tableData="streetScheduleData" :tableColumn="streetScheduleColumn" ></VeTable>
            </div>
          </div>
        </Card>

        <Card class="card-margin" v-if="navList.length == 3">
          <TitleColor title='社区建设进度情况'></TitleColor>
          <VeTable :tableData="communityProgressData" :tableColumn="communityProgressColumn"></VeTable>

          <VeBar :showSelect="false" :data='communityProgress' ></VeBar>

        </Card>

        <!-- 社区 -->
        <Card class="card-margin" v-show="navList.length == 4">
          <TitleColor title='社区组织动员体系建设进度情况'></TitleColor>
          <VeTable :tableData='mobilizationData' :tableColumn='mobilizationColumn' ></VeTable>
        </Card>

        <Card class="card-margin" v-if="navList.length == 4">
          <TitleColor title='建设总览'></TitleColor>
          <Construction  :communityCode='navList[navList.length-1].orgCode'></Construction>
        </Card>

        <Card class="card-margin" v-show="navList.length == 4">
          <TitleColor title='建群情况'></TitleColor>
          <VeTable :tableData="groupData" :tableColumn="groupColumn"></VeTable>
        </Card>

        
      </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from '../components/LiefengContent2.vue'
import Construction from './children/construction.vue'
import Histogram from 'v-charts/lib/histogram.common'
import TitleColor from '../components/TitleColor.vue'
import VeRing from '../components/VeRing'
import Ring from 'v-charts/lib/ring.common'
import VeTable from '../components/Table'
import Bar from 'v-charts/lib/bar.common'
import VeBar from '../components/VeBar'
import Vline from 'v-charts/lib/line.common'
import {mixinRequest} from '../utils/mixinRequest'
export default {
    components:{LiefengContent,Histogram,TitleColor,VeRing,Ring,VeTable,Bar,VeBar,Vline,Construction},
    mixins:[mixinRequest],
    data(){
        return{
            // 面包屑导航栏
            navList:[],
            // 当前页面标题
            titleName:'组织动员体系建设进度情况',
            // 存放用户信息
            portalInfo:{},

            // 绑定的搜索参数
            selectValue:'',
            // 绑定的选择框列表
            selectList:[],


      // 进度
      schedule:{
        columns:['进度','个数'],
        rows:[]
      },
      scheduleo:{
        columns:['进度','个数'],
        rows:[]
      },
      scheduleColumn:[
        {
          title:'街道名称',
          key:'name',
          align: "center",
          render:(h,params) => {
            return h('div',[
              h('a',{
                on:{
                  'click':()=>{
                  // 点击跳转
                    let obj = {
                      label:params.row.name,
                      value:params.row.orgCode
                    }
                    this.changeSelect(obj)
                  }
                }
              },params.row.name)
            ])
          }
        },
        {title:'未启动',key:'notStarted',align: "center",},
        {title:'仅导入架构',key:'importOnly',align: "center",},
        {title:'仅创建工作群',key:'buildGroup',align: "center",},
        {title:'使用中',key:'useing',align: "center",},
      ],
      scheduleData:[],
      streetScheduleColumn:[
        {title:'建设情况',key:'situation',align: "center",},
        {title:'社区数量',key:'num',align: "center",},
        {title:'占比',key:'percent',align: "center",},
      ],
      streetScheduleData:[],



      //街道建设进度情况
      streetBuild:{
        columns:['街道','未启动','仅导入架构','仅创建工作群','使用中' ],
        rows:[]
      },
      streetBuildExtend:{
        stack: 'total',
      },

      // 社区建设进度
      communityProgressColumn:[
        {title:'排名',key:'no',align: "center",},
        {title:'社区',align: "center",
          render:(h,params) => {
            return h('div',[
              h('a',{
                on:{
                  'click':()=>{
                  // 点击跳转
                    let obj = {
                      label:params.row.name.split(',')[1],
                      value:params.row.name.split(',')[0]
                    }
                    this.changeSelect(obj)
                  }
                }
              },params.row.name.split(',')[1])
            ])
          }
        },
        {title:'建设情况',key:'situation',align: "center",},
        {title:'进度',minWidth:800,
          render:(h,params) => {
            return h('div',[
              h(
                "Steps",
                {
                  props: {
                      
                      current: params.row.progress.status
                  }
                },
                [
                  h(
                    "Step",
                    {
                      props:{
                        title:'未启动',
                      }
                    }
                  ),
                  h(
                    "Step",
                    {
                      props:{
                        title:'仅导入架构',
                      }
                    }
                  ),
                  h(
                    "Step",
                    {
                      props:{
                        title:'仅建工作群',
                      }
                    }
                  ),
                  h(
                    "Step",
                    {
                      props:{
                        title:'使用中',
                      }
                    }
                  ),
                ]
              )
            ],
            
            )
          }
        }
      ],
      communityProgressData:[],

      communityProgress:{
        columns:['社区','进度%'],
        rows:[]
      },


      // 社区动员体系建设进度情况
      mobilizationColumn:[
        {title:'建设情况',key:'situation',align: "center",},
        {title:'状态',align: "center",key:'status'},
        {title:'启动时间',align: "center",
          render:(h,params) => {
            return h('div',{},params.row.time?this.$core.formatDate(new Date(params.row.time),"yyyy-MM-dd"):'')
          }
        },
      ],
      mobilizationData:[],

        //建群情况
      groupColumn:[
        {title:'网格群类型',key:'name',align: "center",},
        {title:'群数量（个）',key:'groupNum',align: "center",},
        {title:'群成员数量',key:'num',align: "center",},
      ],
      groupData:[],

      // 建设总览
      buildData:{}


        
    }
    },
    async created(){
        this.portalInfo = this.$store.state.portal ? this.$store.state.portal : {}
        if(this.portalInfo){
            if(this.portalInfo.orgLevel == 3 & !this.$route.query.orgCode){
                // 如果当前是区，进来是街道
                this.titleName = this.portalInfo.orgName + '组织动员体系建设进度情况'
                this.selectValue = ''
                this.selectList =  this.formatterData(await this.getSelectList(this.portalInfo.orgCode))
                this.navList.push(...(JSON.parse(this.$route.query.router)),{
                  name:this.titleName,
                  router:this.$route.path,
                  orgCode:this.portalInfo.orgCode
                })
                await this.getZone()
            }else if(this.portalInfo.orgLevel == 3 && this.$route.query.orgCode){
                this.titleName = this.$route.query.orgName + '组织动员体系建设进度情况'
                this.selectValue = ''
                this.selectList =  this.formatterData(await this.getSelectList(this.$route.query.orgCode))

                this.navList.push(...(JSON.parse(this.$route.query.router)),{
                  name:this.titleName,
                  router:this.$route.path,
                  orgCode:this.$route.query.orgCode
                })
                await this.getZone(this.$route.query.orgCode)
            }
        }
        
    },
    methods:{
      async getZone(code){
        
        //区
        if(this.navList.length == 2){
            this.scheduleData = []
            this.streetBuild.rows = []
            this.streetScheduleData = []
            this.schedule.rows = []
            let data1 = await this.getConstructionStatusTotal(this.portalInfo.orgCode)
            this.schedule.rows=[
              {进度:'使用中',个数:data1.usingTotal},
              {进度:'仅建工作群',个数:data1.buildGroupTotal},
              {进度:'仅导入架构',个数:data1.importSchemaTotal},
              {进度:'未启动',个数:data1.notInitiatedTotal},
            ]
            
            data1.dataList.map( item => {
              this.scheduleData.push({orgCode:item.orgCode,name:item.orgName,notStarted:item.notInitiated,importOnly:item.importSchema,buildGroup:item.buildGroup,useing:item.using})
              this.streetBuild.rows.push(
                {'街道':item.orgName,'未启动':item.notInitiated,'仅导入架构':item.importSchema,'仅创建工作群':item.buildGroup,'使用中':item.using}
              )
          })
        }
        // 街道
        else if(this.navList.length == 3){
          this.streetScheduleData = []
          this.scheduleo.rows = []
          this.communityProgressData = []
          this.communityProgress.rows = []
            let data2 = await this.getOrgMobilizeBuildProgress(code)
            data2.map( item => {
              this.streetScheduleData.push(
                {situation:item.key,num:item.value,percent:item.ext}
              )
              this.scheduleo.rows.push(
                {进度:item.key,个数:item.value}
              )
            })
            let data3 = await this.getConstructionProgress(code)
            data3.map( item => {
              this.communityProgressData.push(
                {no:item.no,name:item.key,situation:item.value,progress:{status:Number(item.ext)-1}}
              )
              this.communityProgress.rows.push(
                {社区:item.key.split(',')[1],'进度%':item.ext==1?'25':item.ext==2?'50':item.ext==3?'75':'100'}
              )
            })
            
        }
        // 社区
        else{
          this.mobilizationData = []
          this.groupData = []
          let data4 = await this.getCommunityOrgMobilization(code)
          data4.map( item => {
            this.mobilizationData.push(
              {situation:item.key,status:item.value==3?'已完成':item.value==2?'未开始':item.value==1?'进行中':'',time:item.ext}
            )
          })

          this.buildData = await this.getOnlineHomeCommunityOverview(code)
          // this.buildData = data
          

          let data6 = await this.getCommunityGroupCondition(code)
          data6.map( item => {
            this.groupData.push(
              {name:item.key,groupNum:item.value,num:item.ext}
            )
          })
        }
        
 

      },

      //选择搜索
      async changeSelect(val){
        if(this.navList.length == 2){
          this.titleName = val.label+"组织动员体系建设进度情况"
          this.selectList = []
          this.selectList = this.formatterData(await this.getSelectList(val.value))
          this.selectValue = ''
          this.navList.push({
              name:this.titleName,
              router:this.$route.path,
              orgCode:val.value
          })
          this.getZone(val.value)
        }else if(this.navList.length == 3){
          this.titleName = val.label+"组织动员体系建设进度情况"
          this.selectList = []
          this.selectList = this.formatterData(await this.getSelectList(val.value))
          this.selectValue = ''
          this.navList.push({
              name:this.titleName,
              router:this.$route.path,
              orgCode:val.value
          })
          this.getZone(val.value)
        }
      },

      // 点击返回& 面包屑按钮
      async clickBar(item,index){
        if(!item){
          // 点击返回
          this.navList.splice(this.navList.length - 1,1)
          this.titleName = this.navList[this.navList.length-1].name
          this.selectValue = ''
          this.selectList =  this.formatterData(await this.getSelectList(this.navList[this.navList.length-1].orgCode))
          await this.getZone(this.navList[this.navList.length-1].orgCode)
        }else{
          // 点击面包屑
          this.navList.splice(index + 1,99)
          this.titleName = item.name
          this.selectValue = ''
          this.selectList =  this.formatterData(await this.getSelectList(item.orgCode))
          await this.getZone(item.orgCode)
        }
        
      }
    }
}
</script>

<style lang="less" scoped>
.header-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.card-margin{
  margin-top: 30px;
}
.stree-div{
    display: flex;
    .left{
        width: 50%;
    }
    .right{
        margin-left: 20px;
        flex: 1;
    }
}
/deep/.ivu-steps{
  background-color: transparent
}
.type{
  display: flex;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  .term{
    margin-right: 10px;
    margin-left: 100px;
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid #999999;
    border-radius: 5px;
    &:nth-of-type(1){
      margin-left: 0px;
    }
  }
  .blue{
    background-color: #32D7DD;
  }
  .green{
    background-color: #33BB00;
  }
}
</style>





