<template>
    <div class="showBar-div" v-if="showBar">
        <Bar class="bar" :height="height" :not-set-unchange="['dataZoom']" :dataZoom="dataZoom" :colors="colors" :extend="extend"  :grid="grid" :data="data" :settings="settings"></Bar>
        <div class="select-div" v-if="showSelect">
            <Select clearable v-model="selectData.value" style="width:200px" @on-change="changeSelect">
                <Option v-for="(item,index) in selectData.list" :key="index" :value="item.value">{{ item.label }}</Option>
            </Select>
        </div>
    </div>
</template>

<script>
import Bar from 'v-charts/lib/bar.common'
export default {
    props:{
        extend:{
            type:Object,
            default:()=>{
                return {
                    barMaxWidth: 30,
                }
            }
        },
        series:{
            type:Array,
            default:()=>{
                return {
                   
                }
            }
        },
        grid:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        data:Object,
        settings:Object,
        selectData:{
            type:Object,
            default:()=>{
                return{
                    value:'',
                    list:[]
                }
            }
        },
        showSelect:{
            type:Boolean,
            default:()=>{
                return true
            }
        }
    },
    components:{Bar},
    data(){
        return{
            showBar:true,
            colors:['#61e9f1','#56a3d5', '#4b83d9','#e6d677','#cfa47c','#2fb56e','#599e1e','#7841ff','#1312d2','#b14140','#b4428c'],
            height:'400px'
        }
    },
    watch:{
        'data.rows':{
            deep:true,
            handler:function(val){
                if(val.length > 10){
                        this.height = val.length * 40 + 'px'
                }
                else this.height = '400px'
                this.showBar = false
                this.$nextTick(()=>{  
                    this.showBar = true
                })
            }
        }
    },
    methods:{
        changeSelect(val){
            if((val ?? '') == '') val = ''
            this.$emit('changeSelect',val)
        }
    }   
}
</script>

<style lang="less" scoped>
.showBar-div{
    position: relative;
    width: 100%;
    height: 100%;
    .bar{
        width: 100%;
        height: 100%;
    }
    .select-div{
        position:absolute;
        top: 0;
        right: 0;
    }
}
</style>