<template>
  <div class="ring-div" v-if="showRing">
      <VeRing class="ring" :colors="colors" :data="ringData" :extend="extend" v-if="ringData.rows && ringData.rows.length > 0 && !showLeft"></VeRing>
      <div class="table-div" v-if="listData.rows && listData.rows.length > 0">
              <div class="item" v-for="(value,key,index) in listData.rows" :key="index">
                  <span v-for="(textValue,name,num) in value" :key="num">
                      <span class="span">{{textValue}}</span>
                  </span>
                </div>
      </div>
      <div class="none-div" v-else>暂无数据</div>
  </div>
</template>

<script>
import VeRing from "v-charts/lib/ring.common";
export default {
    props:['ringData','extend','listData','showLeft'],
    components:{
        VeRing
    },
    data(){
        return{
            showRing:true,
            colors:['#61e9f1','#56a3d5', '#4b83d9','#e6d677','#cfa47c','#2fb56e','#599e1e','#7841ff','#1312d2','#b14140','#b4428c']
        }
    },
    watch:{
        ringData(){
            this.showRing = false
            this.$nextTick(()=>{
                this.showRing = true
            })
        }
    }
}
</script>

<style lang="less" scoped>
.ring-div{
    width: 100%;
    height: 400px;
    display: flex;
    .ring{
        width:60% !important;
        height:100%;
    }
    .table-div{
        flex: 1;
        height: 100%;
        border: 1px solid rgb(224, 224, 224);
        border-radius: 10px;
        overflow: scroll;
        .item{
            padding: 10px 20px;
            .span:nth-child(1){
                padding-left: 10px;
            }
        }
    }
    .none-div{
        height: 400px;
        line-height: 400px;
        color: #999;
        text-align: center;
        width: 100%;
    }
}
</style>