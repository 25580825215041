<template>
  <div class="container">
      <div class="top">
          <div class="item background1">
              <div class="top-div">
                  <div class="div-left">
                      <div class="title">总网格数:</div>
                      <div class="num">{{tableData.gridTotal || 0}} <span class="span">个</span></div>
                  </div>
                  <div class="div-right">
                      <div class="num1">有户代表: {{tableData.settleGridTotal || 0}}</div>
                      <div class="num2">无户代表: {{tableData.gridTotal - tableData.settleGridTotal > 0 ? tableData.gridTotal - tableData.settleGridTotal : 0}}</div>
                  </div>
              </div>
              <div class="bottom-div">
                  <Progress :percent="tableData.settleGridTotal && tableData.settleGridTotal > 0 ? ((tableData.settleGridTotal/tableData.gridTotal) * 100) > 100 ? 100 : ((tableData.settleGridTotal/tableData.gridTotal) * 100) :0" :stroke-color="['#41ABF7', '#ccd3f8']">
                       <Icon type="checkmark-circled"></Icon>
                            <span>{{tableData.settleGridTotal && tableData.settleGridTotal > 0 ? (((tableData.settleGridTotal/tableData.gridTotal) * 100) | filtersNum)  :0}}%</span>
                  </Progress>
              </div>
          </div>
          <div class="item background2">
              <div class="top-div">
                  <div class="div-left">
                      <div class="title">总楼栋数:</div>
                      <div class="num">{{tableData.buildingTotal || 0}} <span class="span">个</span></div>
                  </div>
                  <div class="div-right">
                      <div class="num1">有栋长:{{tableData.settleBuildingTotal || 0}}</div>
                      <div class="num2">无栋长:{{tableData.buildingTotal - tableData.settleBuildingTotal > 0 ? tableData.buildingTotal - tableData.settleBuildingTotal : 0 }}</div>
                  </div>
              </div>
              <div class="bottom-div">
                  <Progress :percent="tableData.settleBuildingTotal && tableData.settleBuildingTotal > 0 ? ((tableData.settleBuildingTotal/tableData.buildingTotal) * 100) > 100 ? 100 : ((tableData.settleBuildingTotal/tableData.buildingTotal) * 100) :0" :stroke-color="['#71bff7', '#b8dbf5']">
                            <Icon type="checkmark-circled"></Icon>
                            <span>{{tableData.settleBuildingTotal && tableData.settleBuildingTotal > 0 ? (((tableData.settleBuildingTotal/tableData.buildingTotal) * 100) | filtersNum) :0}}%</span>
                  </Progress>
              </div>
          </div>
          <div class="item background3">
              <div class="top-div">
                  <div class="div-left">
                      <div class="title">总户数:</div>
                      <div class="num">{{tableData.houseTotal || 0}} <span class="span">个</span></div>
                  </div>
                  <div class="div-right">
                      <div class="num1">有户代表:{{tableData.settleHouseTotal || 0}}</div>
                      <div class="num2">无户代表:{{tableData.houseTotal - tableData.settleHouseTotal > 0 ? tableData.houseTotal - tableData.settleHouseTotal : 0}}</div>
                  </div>
              </div>
              <div class="bottom-div">
                  <Progress :percent="tableData.settleHouseTotal && tableData.settleHouseTotal > 0 ? ((tableData.settleHouseTotal/tableData.houseTotal) * 100) > 100 ? 100 : ((tableData.settleHouseTotal/tableData.houseTotal) * 100) :0" :stroke-color="['#fca6b8', '#f1c0cb']">
                       <Icon type="checkmark-circled"></Icon>
                            <span>{{tableData.settleHouseTotal && tableData.settleHouseTotal > 0 ? (((tableData.settleHouseTotal/tableData.houseTotal) * 100)|filtersNum) :0}}%</span>
                  </Progress>
              </div>
          </div>
          <div class="item background4">
              <div class="top-div">
                  <div class="div-left">
                      <div class="title">总楼组:</div>
                      <div class="num">{{tableData.buildingGroupTotal || 0}} <span class="span">个</span></div>
                  </div>
                  <div class="div-right">
                      <div class="num1">有楼组代表:{{tableData.settleBuildingGroupTotal || 0}}</div>
                      <div class="num2">无楼组代表:{{tableData.buildingGroupTotal - tableData.settleBuildingGroupTotal > 0 ? tableData.buildingGroupTotal - tableData.settleBuildingGroupTotal  : 0}}</div>
                  </div>
              </div>
              <div class="bottom-div">
                  <Progress :percent="tableData.settleBuildingGroupTotal && tableData.settleBuildingGroupTotal > 0 ?((tableData.settleBuildingGroupTotal/tableData.buildingGroupTotal) * 100) > 100 ? 100 : ((tableData.settleBuildingGroupTotal/tableData.buildingGroupTotal) * 100) :0" :stroke-color="['#59be94', '#d1f5e6']">
                      <Icon type="checkmark-circled"></Icon>
                            <span>{{tableData.settleBuildingGroupTotal && tableData.settleBuildingGroupTotal > 0 ? (((tableData.settleBuildingGroupTotal/tableData.buildingGroupTotal) * 100)| filtersNum) :0}}%</span>
                  </Progress>
              </div>
          </div>
      </div>
      <div class="center">
          <div class="desc">图像说明:</div>
          <div class="white">
              <div class="left"></div>
              <div class="right">
                  <div>{{tableData.gridTotal - tableData.settleGridTotal > 0 ? tableData.gridTotal - tableData.settleGridTotal : 0}}</div>
                  <div>未入驻网格</div>
              </div>
          </div>
          <div class="white">
              <div class="left"></div>
              <div class="right">
                  <div>{{tableData.houseTotal - tableData.settleHouseTotal > 0 ? tableData.houseTotal - tableData.settleHouseTotal : 0}}</div>
                  <div>未入驻户数</div>
              </div>
          </div>
          <div class="red">
               <div class="left"></div>
                <div class="right">
                    <div>{{tableData.settleGridTotal || 0}}</div>
                    <div>已入驻网格员</div>
                </div>
          </div>
          <div class="yellow">
                <div class="left"></div>
                <div class="right">
                    <div>{{tableData.settleBuildingTotal || 0}}</div>
                    <div>已入驻楼(组)长</div>
                </div>
          </div>
      </div>

      <div class="bottom-child">
          <div class="child" v-for="(item,index) in treeData" :key="index">
              <div :class="item.manager > 0 ? 'top child-top' :'top'">
                <div class="name">网格名称： {{item.name | spliceTitle}}</div>
                <div class="build">楼(组)共有：{{item.childs ? (item.childs | spliceNum) : 0}}</div>
                <div class="builed">已有楼(组)长：{{item.nodeManager ? item.nodeManager : 0}}</div>
              </div>
              <div class="child-view">
                  <div :class="items.category != '5' ? 'build-view' : ''" v-for="(items,indexs) in item.childs" :key="indexs" @click="openModal(items.category,item,items)">
                      <template v-if="items.category != '5'">
                        <div :class="items.nodeManager ?'item yellow':'item'">
                            <div class="image">
                                <img class="img" :src="buildimage" alt="" />
                            </div>
                            <div class="text">
                                <div class="title">{{items.name | spliceTitle}}</div>
                                <div class="title2">{{items.nodeManager ? '已入驻' : '未入驻'}}</div>
                                </div>
                        </div>
                        <div class="text-view">
                            <div class="text-item">
                                <div class="title">楼总户数</div>
                                <div class="num"  >{{items.childs ? items.childs.length : 0}}</div>
                            </div>
                            <div class="text-item">
                                <div class="title">有户代表</div>
                                <!-- <div class="num"  >{{items.nodeManager ? items.nodeManager : 0}}</div> -->
                                <div class="num"  >{{items.childNodeManager ? items.childNodeManager : 0}}</div>
                            </div>
                            <div class="text-item">
                                <div class="title">无户代表</div>
                                <div class="num"  >{{(items.childs.length - items.nodeManager) > 0  ? (items.childs.length - items.nodeManager) : 0}}</div>
                            </div>
                        </div>
                        <div class="precent">
                            <Progress :percent="items.childNodeManager != 0 ? ((items.childNodeManager/items.childs.length) * 100) > 100 ? 100 : ((items.childNodeManager/items.childs.length) * 100) : 0 " :stroke-color="['#59be94', '#d1f5e6']">
                            <Icon type="checkmark-circled"></Icon>
                                    <span style="color:#999999 ">{{items.childNodeManager != 0 ? ((items.childNodeManager/items.childs.length) * 100) > 100 ? 100 : (((items.childNodeManager/items.childs.length) * 100)|filtersNum) : 0}}%</span>
                            </Progress>
                        </div>    
                      </template>
                  </div>
              </div>
          </div>
      </div>
      <!--设置负责区域-->
      <LiefengModal :value="messageRegion"  @input="regionInput" title="社区组织动员体系建设情况总览" :fullscreen="true" >
          <template v-slot:contentarea>
              <Build ref='build' :isShow="showNumber" :tableData="buildTableData" :category="category" :tableData2="buildTableData2"></Build>
          </template>
          <template v-slot:toolsbar>
            <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="sendRegion">确定</Button>
          </template>
      </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import Build from './build.vue'
import buildimage from '../../../../../public/images/build.png'
export default {
    components:{LiefengModal,Build},
    props:{
        communityCode:String,
    },
    data(){
        return{
            messageRegion:false,
            showNumber:0,
            tableData:{},
            treeData:[],
            buildTableData:[],
            buildTableData2:[],
            category:'',
            isNum:0,
            buildimage:buildimage,
            numNode:0
        }
    },
    created(){
        this.getList(this.communityCode)
    },
    
    filters:{
        spliceTitle(option){
            if(option.length >10){
                return option = option.slice(0, 10) + '...'
            }else{
                return option
            }
        },
        spliceNum(option){
            let data = option
            if(data){
                data.map((item,index) =>{
                    if(item.category == 5){
                        data.slice(index,1)
                    }
                })
            }
            return data
        },
        filtersNum(options){
            if(options.indexOf('.') > -1){
                const reg = /([0-9]+\.[0-9]{2})[0-9]*/
                return options = options.replace(reg,'$1')
            }else{
                return options
            }
        }
    },
    methods:{
       
        openModal(nums,item,items){
            
            this.buildTableData = []
            this.buildTableData2  = []
            this.category = nums
            if(nums && nums == 2){
                 this.buildTableData2 = items
                 this.messageRegion = true
                 this.showNumber++
            }else if(nums && nums == 3){
                this.buildTableData = items
                let index = 0
                if(items && items.childs != null && items.childs.length != 0){
                    items.total = items.childs.length
                    items.childs.map(item =>{
                        if(item.nodeManager != 0){
                            index = index + Number(item.nodeManager)
                        }
                    })
                    items.num = index
                }
                 this.messageRegion = true
                 this.showNumber++
            }
        },
        regionInput(status){
            this.messageRegion = status
        },
        sendRegion(){
            this.messageRegion = false
        },
        getList(orgCode){ 
            this.$get('/orgzz/pc/grid/buildGridArchitectureTree',{
                orgCode,
                oemCode:parent.vue.oemInfo.oemCode
            }).then(res=>{
                // this.loadingBtn = false
               this.$Message.destroy()
                if(res.code == 200){
                    this.tableData = res.data
                    this.treeData = []
                    if(res.data.tree && res.data.tree.length != 0) {
                        
                      this.formatter(res.data.tree) 
                      this.getNodeFormatter(this.treeData)
                      this.formatterNodeManager(this.treeData)
                    }
                    
                }else{
                    this.$Message.error({
                        conetnt:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        formatter(data){
            if(data && data.length != 0){
                data.map(item=>{
                    if(item.category == '1'){
                        this.treeData.push(item)
                    }else if(item.category == '2'){
                        this.formatter(item.childs)
                        return
                    }else{
                        if(item.childs && item.childs.length != 0){
                            this.formatter(item.childs)
                            return
                        }
                    }
                })
            }
        },
        // 获取房号的代表
        getNodeFormatter(data){
            data.map(item =>{
                if(item.category == '2'){
                    item.childNodeManager = 0
                    this.getNodeChildNum(item.childs)
                }else{
                    this.getNodeFormatter(item.childs)
                }
            })
        },
        getNodeChildNum(data){
            if(data && data.length > 0){
                data.map(item =>{
                    if(item.category == '3'){
                        item.getNodeChildNum = 0
                        if(item.childs && item.childs.length > 0){
                            item.childs.map(items =>{
                                if(items.category == '4'){
                                    item.getNodeChildNum = item.getNodeChildNum + items.nodeManager
                                }
                            })
                        }
                    }
                })
            }
            // return num
        },
        formatterNodeManager(data){
            if(data && data.length != 0){
                data.map(item =>{
                    if(item.category == '2'){
                        item.childNodeManager = 0
                        if(item.childs && item.childs.length > 0){
                            item.childs.map(items =>{
                                item.childNodeManager = item.childNodeManager + items.getNodeChildNum
                            })
                        }
                    }else{
                        this.formatterNodeManager(item.childs)
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    width: 100%;
    .clearser-div{
        width: 100%;
        height: 40px;
        display: flex;
        
    }
    .top{
        width: 100%;
        display: flex;
        justify-content: center;
        // flex-wrap: wrap;
        .item{
            font-size: 16px;
            width: 25%;
            height: 160px;
            padding: 20px;
            color: #ffffff;
            border-radius: 8px;
            margin: 0 20px;
            .top-div{
                display: flex;
                justify-content: space-between;
                height:100px;
                .div-left{
                    .title{
                        opacity: 0.5;
                        font-size: 18px;
                        line-height: 32px;
                    }
                    .num{
                        font-size: 40px;
                        line-height: 70px;
                        .span{
                            font-size: 16px;
                            line-height: 28px;
                            color: #FFFFFF;
                            opacity: 0.5;
                            padding-left: 20px;
                        }
                    }
                }
                .div-right{
                    font-size: 20px;
                    margin-right: 20px;
                    .num1{
                        line-height: 32px;
                    }
                    .num2{
                        line-height: 32px;
                        margin-top: 20px;
                    }
                }
            }
            .bottom-div{
                height: 60px;
            }
        }
        .background1{
            background-image: linear-gradient(to right,#5770FB, #C577FF)
        }
        .background2{
            background-image: linear-gradient(to right,#40ACF7,#737CFF)
        }
        .background3{
            background-image: linear-gradient(to right,#ED6281,#F09D5E)
        }
        .background4{
            background-image: linear-gradient(to right,#3DB684,#95CF4B)
        }
    }
    .center{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        border-top: 1px solid rgb(228, 227, 227);
        border-bottom: 1px solid rgb(228, 227, 227);
        padding-top: 15px;
        margin: 20px 0;
        .desc,.white,.red,.yellow,.blue{
            margin-right: 20px;
            display: flex;
        }
        .desc{
            margin-left: 20px;
            font-size: 16px;
            line-height: 50px;
        }
        .white{
            padding: 5px 0 ;
            .left{
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background: #cccccc;
                margin-right: 10px;
            }
            .right{
                text-align: center;
            }
        }
        .red{
            padding: 5px 0 ;
            .left{
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 10px;
                background-color: #55C794;
            }
            .right{
                text-align: center;
            }
        }
        .yellow{
            padding: 5px 0 ;
            .left{
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 10px;
                background-color: #EE866B;
            }
            .right{
                text-align: center;
            }
        }
        .blue{
            padding: 5px 0 ;
            .left{
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 10px;
                background-color: #EE866B;
            }
            .right{
                text-align: center;
            }
        }
    }
    .bottom{
        
        .bottom-view{
            display: flex;
            justify-content: flex-start;
            margin: 20px 0 ;
            .left{
                    width: 180px;
                    word-break: wrap;
                    padding:10px 20px 20px 20px;
                    border: 1px solid #ccc;
                    margin-right: 20px;
                    height: 100px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                    -webkit-box-orient: vertical;
                    text-align: center;
                    &none{
                        background-color: #55C794;
                        color:white;
                    }
                }   
                .right{
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .item{
                        border: 1px solid #ccc;
                        margin: 0px 20px 20px 0;
                        width: 200px;
                        height: 100px;
                        position: relative;
                        user-select: none;
                        cursor: pointer;
                        .content{
                            position: absolute;
                            margin: 10px;
                            width: 180px;
                            height: 80px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            line-clamp: 4;
                            -webkit-box-orient: vertical;
                            text-align: center;
                        }
                    }
                    .red{
                        background-color: #55C794;
                        color: #ffffff;
                    }
                    .yellow{
                        background-color: #EE866B;
                        color: #ffffff;
                    }
                    .blue{
                        background-color: #55C794;
                        color: #ffffff;
                    }
                }
        }
        
    }
    .bottom-child{
        height: 100%;
        .child{
            color: #ffffff;           
           .top{
               width: 680px;
               height: 80px;
               border-radius:6px;
               display: flex;
               background:#CCCCCC;
               font-size: 20px;
               justify-content: flex-start;
               height: 80px;
               line-height: 80px;
               min-width: 680px;
               margin: 20px 0;
               padding-right: 0 20px;
           }
           .child-top{
               background: #55C794;
           }
           .name{
               padding-left: 20px;
           }
           .build{
               padding-left: 20px;
           }
           .builed{
               margin-left: 20px;
               margin-right: 20px;
           }
           .child-view{
               width: 100%;
               margin: 20px 20px 20px 0;
               display: flex;
                flex-wrap: wrap;
               .build-view{
                   width: 22%;
                   margin-right: 20px;
                   height: 176px;
                   background: #FDFFFF;
                   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                   opacity: 1;
                   border-radius: 6px;
                   cursor: pointer;
                   user-select: none;
                   color: black;
                   margin-bottom: 20px;
                   &:hover{
                       box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
                   }
                   .item{
                       background: #cccccc;
                       border-top-left-radius: 6px;
                       border-top-right-radius: 6px;
                       display: flex;
                       height: 72px;
                       padding: 10px 20px;
                   }
                   .yellow{
                       background: #EE866B;
                   }
                   .image{
                       margin-right: 5px;
                       .img{
                           width: 34px;
                           height: 34px;
                       }
                   }
                   .text{
                       .title{
                           font-size: 16px;
                            color: #FFFFFF;
                            opacity: 1;
                       }
                       .title2{
                           font-size: 12px;
                            color: #FFFFFF;
                            opacity: 1;
                       }
                    }
                    .text-view{
                        display: flex;
                        justify-content: flex-start;
                        padding-top: 20px;
                        .text-item{
                            width: 33.3%;
                            text-align: center;
                            .title{
                                font-size: 14px;
                                line-height: 21px;
                                color: #999999;
                            }
                            .num{
                                font-size: 14px;
                                line-height: 25px;
                                color: #393939;
                            }
                        }
                        
                    }
                    .precent{
                            height: 30px;
                            margin-left: 10px;
                        }
               }
               
           }
        }
    }
    /deep/.ivu-card{
        background: none;
    }
    /deep/.ivu-card-bordered{
        border: none;
        color: #ffffff;
    }
    /deep/.ivu-progress-text{
        color: #ffffff;
    }
}
</style>