/*
    mixin使用注意：
        1、不同组件调用这个mixin，data中的值会设为初始值，比如组件1使用了自增了num，那么组件2调用的时候，num的值依旧是初始值，不会受组件1的调用而改变
        2、created执行顺序，如果在mixin里写了created方法，组件会先调用mixin函数里的created周期函数，再去调用组件里的created周期函数(由于我在mixin函数里加了async awaait这个方法，组件的created函数不会等待mixincreated函数跑完promise异步代码再去执行，所以会先执行组件里边的created钩子函数)
        3、methods函数，如果mixin函数命名与组件函数命名一样，只会调用组件的函数，不会调用mixin的函数
*/

// orgLevel:  4街道；5社区；3区；2市；1省。
export const mixinRequest = {
    methods:{
        // 获取当前账号社区状态
       async getOrg(){
            await this.$get('/gx/pc/staff/selectStaffManageOrg',{
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId
            }).then(res=>{
                if(res.code == 200){
                    if(res.dataList && res.dataList.length > 0){
                        var num = null
                        var list = null
                        res.dataList.map(item =>{
                            if(num != null && num > item.orgLevel){
                                    num = item.orgLevel
                                    list = item
                            }else{
                                num = item.orgLevel
                                list = item
                            }
                        })

                        this.$store.commit('portalInfo', list)
                    }
                }else{
                    this.$Message.error({
                        content:'当前用户信息获取失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 获取区级下的街道使用情况
       async getOrgStreeUsage(zoneOrgCode){
           let res = await this.$get('/gx/pc/organization/selectCommunityUsageByZone',{
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:zoneOrgCode ? zoneOrgCode : ''
            })
                if(res.code == 200){
                    return res.data
                }else{
                    this.$Message.error({
                        content:'街道使用情况数据获取失败',
                        background:true
                    })
                    return
                }
        },
        // 获取街道维度的社区使用情况
       async getStreeList(streetOrgCode){
            let res =  await this.$get('/gx/pc/organization/selectCommunityUsageByStreet',{
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:streetOrgCode ? streetOrgCode : ''
            })
   
            if(res.code == 200){
                return res.data
            }else{
                this.$Message.error({
                    content:'街道使用情况数据获取失败',
                    background:true
                })
                return
            }
        },
        // 权限获取街道/社区接口
       async getSelectList(orgCode){
        let res =  await this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:orgCode ? orgCode : '',
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            })
            if(res.code == 200 ){
                    return res.dataList
            }else{
                this.$Message.error({
                    content:'获取选择数据失败',
                    background:true
                })
                return
            }
       },
       // 社区获取机构机构
       async getOrgManages(orgCode){
            let res = await this.$get('/orgzz/pc/organizationScope/selectOrgManages',{
                orgCode,
            })
            if(res.code == 200 && res.dataList.length != 0){
                return res.dataList
        }else{
            this.$Message.error({
                content:'获取选择数据失败',
                background:true
            })
            return
        }
       },

        //  用户分布情况

        //用户增长
        async getStatUserOnTimeByOrgCode(date,orgCode,startDate,endDate){
            let res = await this.$get('/statistic/api/symanage/pc/syuser/statUserOnTimeByOrgCode',{
                date,
                orgCode,
                startDate,
                endDate,
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'用户增长数据获取失败',
                    background:true
                })
                return
            }
        },
        // 用户画像 - 累计用户
        async getStatUserPortraitByOrgCode(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/syuser/statUserPortraitByOrgCode',{
                orgCode,
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'累计用户数据获取失败',
                    background:true
                })
                return
            }
        },
        //用户分布情况
        async getStatUserByOrgCode(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/syuser/statUserByOrgCode',{
                orgCode,
                oemCode:parent.vue.oemInfo.oemCode
            })
            if(res.code == 200){
                let data = JSON.parse(JSON.stringify(res.data))
                data.streetVoList = data.streetVoList.sort(this.sorts_fun('totalOrgUser'))
                data.streetVoList.map((e,i) => {
                    e.no = i+1
                })
                return data
                
            }else{
                this.$Message.error({
                    content:'用户分布情况数据获取失败',
                    background:true
                })
                return
            }
        },
        // 获取区人群类型
        async getZoneUserByType(zoneCode){
            let res = await this.$get('/syaa/pc/sy/stat/getZoneUserByType',{
                zoneCode:zoneCode
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'人群类型数据获取失败',
                    background:true
                })
                return
            }
        },
        // 获取街道人群类型
        async getStreetUserByType(streetCode){
            let res = await this.$get('/syaa/pc/sy/stat/getStreetUserByType',{
                streetCode:streetCode
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'人群类型数据获取失败',
                    background:true
                })
                return
            }
        },
        // 获取社区人群类型
        async getCommunityUserByType(communityCode){
            let res = await this.$get('/syaa/pc/sy/stat/getCommunityUserByType',{
                communityCode:communityCode
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'人群类型数据获取失败',
                    background:true
                })
                return
            }
        },
        //获取年龄分布
        async getZoneCategoryByAge(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/syuser/getCategoryByAge',{
                orgCode:orgCode
            })
            if(res.code == 200 && res.dataList.length != 0){
                return res.dataList
            }else if(res.code == 200 && res.dataList.length == 0){
                let list = [
                    {key: "0-17岁", value: 0, ext: "0"},
                    {key: "18-24岁", value: 0, ext: "0"},
                    {key: "25-29岁", value: 0, ext: "0"},
                    {key: "30-39岁", value: 0, ext: "0"},
                    {key: "40-49岁", value: 0, ext: "0"},
                    {key: "50-59岁", value: 0, ext: "0"},
                    {key: "59岁以上", value: 0, ext: "0"},
                    {key: "其他", value: 0, ext: "0"},
                ]
                return list
            }else{
                this.$Message.error({
                    content:'年龄分布数据获取失败',
                    background:true
                })
                return
            }
            
        },
        //获取性别分布
        async getZoneCategoryByGender(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/syuser/getCategoryByGender',{
                orgCode:orgCode
            })
            if(res.code == 200 && res.dataList.length != 0){
                return res.dataList
            }else if(res.code == 200 && res.dataList.length == 0){
                let list = [
                    {key: "男", value: 0, ext: "0"},
                    {key: "女", value: 0, ext: "0"},
                    {key: "未知", value: 0, ext: "0"},
                ]
                return list
            }else{
                this.$Message.error({
                    content:'性别数据获取失败',
                    background:true
                })
                return
            }
        },


        //获取区属地化情况
        async getCompanyByZone(zoneCode){
            let res = await this.$get('/syaa/pc/sy/stat/getCompanyByZone',{
                zoneCode:zoneCode,
                oemCode:parent.vue.oemInfo.oemCode
            })
            if(res.code == 200){
                res.data.dataByStreetList.map((e,index) => {
                    e.no = index+1
                })
                return  res.data
            }else{
                this.$Message.error({
                    content:'属地化数据获取失败',
                    background:true
                })
                return
            }
        },
        //获取社区属地化情况
        async getCompanyByCommunity(communityCode){
            let res = await this.$get('/syaa/pc/sy/stat/getCompanyByCommunity',{
                communityCode:communityCode,
                oemCode:parent.vue.oemInfo.oemCode
            })
            if(res.code == 200){
                return  res.data
            }else{
                this.$Message.error({
                    content:'属地化数据获取失败',
                    background:true
                })
                return
            }
        },


        // 按机构获取子机构列表统计数据/各街道、社区使用情况
        async getSelectOrgChildInfo(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/orgManageOverall/selectOrgChildInfo',{
                orgCode:orgCode ? orgCode : ''
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'栏目信息获取失败',
                    background:true
                })
                return
            }
        },
        // 按机构获取属地机构列表统计数据
        async getSelectOrgManageInfo(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/orgManageOverall/selectOrgManageInfo',{
                orgCode: orgCode ? orgCode : ''
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'栏目信息获取失败',
                    background:true
                })
                return
            }
        },
        // 按层级获取栏目开通数，栏目数据发布情况
        async selectOrgChildInfo(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/columnOverall/selectOrgChildInfo',{
                orgCode: orgCode ? orgCode : ''
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'栏目信息获取失败',
                    background:true
                })
                return
            }
        },
        // 按层级获取子集栏目开通树，栏目数据发布情况
        async selectOrgInfo(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/columnOverall/selectOrgInfo',{
                orgCode: orgCode ? orgCode : ''
            })
            if(res.code == 200){
                return res.data
            }else{
                this.$Message.error({
                    content:'栏目信息获取失败',
                    background:true
                })
                return
            }
        },
        // 获取街道、社区的管理员接口
        async leaveOrgChildInfo(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/orgManageOverall/selectOrgChildInfo',{
                orgCode: orgCode ? orgCode : ''
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'栏目信息获取失败',
                    background:true
                })
                return
            }
        },
        // 获取最后一个层级的接口
        async leaveLastOrgChildInfo(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/orgManageOverall/selectOrgManageInfo',{
                orgCode: orgCode ? orgCode : ''
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'栏目信息获取失败',
                    background:true
                })
                return
            }
        },
        // 栏目获取更新频率
        async selectColumnFrequency(params){
            let res = await this.$get('/statistic/api/symanage/pc/columnOverall/selectOrgColumnFrequency',{
                orgCode:params.orgCode ? params.orgCode : '',
                startDate:params.startDate ? params.startDate : '',
                endDate:params.endDate ? params.endDate : ''
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'栏目信息获取失败',
                    background:true
                })
                return
            }
        },
        async selectInformationBehavior(params){
            let res = await this.$get('/statistic/api/symanage/pc/columnOverall/selectInformationBehavior',{
                orgCode:params.orgCode ? params.orgCode : '',
                statType:params.statType ? params.statType : '' // 1、点击 2、参与
            })  
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'用户关注度分析数据获取失败',
                    background:true
                })
                return
            }
        },
        async selectOverallOrgManageInfo(orgCode){
            let res =await this.$get('/statistic/api/symanage/pc/columnOverall/selectOrgManageInfo',{
                orgCode: orgCode ? orgCode : ''
            })
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'各服务主体栏目统计数据获取失败',
                    background:true
                })
                return
            }
        },


        //获取区动员体系建设进度情况
        async getConstructionStatusTotal(zoneCode){
            let res = await this.$get('/statistic/api/symanage/pc/syuser/getConstructionStatusTotal',{
                orgCode:zoneCode,
            })
            if(res.code == 200){
                // res.data.dataByStreetList.map((e,index) => {
                //     e.no = index+1
                // })
                return  res.data
            }else{
                this.$Message.error({
                    content:'组织动员体系建设进度情况获取失败',
                    background:true
                })
                return
            }
        },
        //获取街道动员体系建设进度情况
        async getOrgMobilizeBuildProgress(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/syuser/getOrgMobilizeBuildProgress',{
                orgCode:orgCode,
                oemCode:parent.vue.oemInfo.oemCode,
            })
            if(res.code == 200){
                return  res.dataList
            }else{
                this.$Message.error({
                    content:'组织动员体系建设进度情况获取失败',
                    background:true
                })
                return
            }
        },
        //获取街道下社区建设进度情况(街道进度条)
        async getConstructionProgress(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/syuser/getConstructionProgress',{
                orgCode:orgCode,
            })
            if(res.code == 200){
                let arr = res.dataList.sort(this.sorts_fun('ext'))
                arr.map((e,index) => {
                    e.no = index+1
                })
                return  arr
            }else{
                this.$Message.error({
                    content:'社区建设进度情况获取失败',
                    background:true
                })
                return
            }
        },
        // 按社区统计建群情况
        async getCommunityGroupCondition(orgCode){
            let res = await this.$get('/sendmsg/pc/chat/stat/getCommunityGroupCondition',{
                orgCode:orgCode,
            })
            if(res.code == 200){
                return  res.dataList
            }else{
                this.$Message.error({
                    content:'组织动员体系建设进度情况获取失败',
                    background:true
                })
                return
            }
        },
        // 建设总览
        async getOnlineHomeCommunityOverview(orgCode){
            let res = await this.$get('/syaa/pc/sy/user/online/home/onlineHomeCommunityOverview',{
                orgCode,
                oemCode:parent.vue.oemInfo.oemCode,
            })
            if(res.code == 200){
                return  res.data
            }else{
                this.$Message.error({
                    content:'建设总览数据获取失败',
                    background:true
                })
                return
            }
        },
        //  按社区统计组织动员体系建设进度
        async getCommunityOrgMobilization(orgCode){
            let res = await this.$get('/sendmsg/pc/chat/stat/getCommunityOrgMobilization',{
                communityCode:orgCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            })
            if(res.code == 200){
                return  res.dataList
            }else{
                this.$Message.error({
                    content:'组织动员体系建设进度情况获取失败',
                    background:true
                })
                return
            }
        },


        // 栏目使用情况
            // 头盒子
        async getSelectColumnUseData(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/columnOverall/selectColumnUseData',{
                orgCode,
                statType:'1'
            })
            if(res.code == 200 ){
                return res.data
            }else{
                this.$Message.error({
                    content:'栏目使用情况获取失败',
                    background:true
                })
                return
            }
        },
        // 栏目统计
        async selectColumnUseDataInfo(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/columnOverall/selectColumnUseDataInfo',{
                orgCode,
                statType:'1'
            })  
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'栏目统计数据获取失败',
                    background:true
                })
                return
            }
        },
        // 下级运营数据
        async selectColumnUseChildData(orgCode){
            let res = await this.$get('/statistic/api/symanage/pc/columnOverall/selectColumnUseChildData',{
                orgCode,
                statType:'1'
            })  
            if(res.code == 200){
                return res.dataList
            }else{
                this.$Message.error({
                    content:'下级运营数据数据获取失败',
                    background:true
                })
                return
            }
        },
        // 用户访问/参与排行榜
        async selectUserBehavior(params){
            let res = await this.$get('/statistic/api/symanage/pc/columnOverall/selectUserBehavior',{
                orgCode:params.orgCode ? params.orgCode : '',
                statType:params.statType ? params.statType : '' // 1、访问 2、参与
            })  
            if(res.code == 200){
                let arr = res.dataList.sort(this.sorts_fun('ext'))
                arr.map((e,index) => {
                    e.no = index+1
                })
                return arr
            }else{
                this.$Message.error({
                    content:'用户排行榜数据获取失败',
                    background:true
                })
                return
            }
        },
        
        


       
        
        // 格式化获取的数据
       // 格式化拿到的参数
       formatterData(data){
        let list = []
        if(data && data.length != 0){
                data.map(item =>{
                    list.push({
                        value:item.orgCode,
                        label:item.orgName,
                        orgLevel:item.orgLevel
                    })
                })
            }
            return list
        },
        // 格式化最后一级的数据
       formatterOrgData(data){
        let list = []
        if(data && data.length != 0){
                data.map(item =>{
                    list.push({
                        value:item.orgCode,
                        label:item.orgName,
                        orgLevel:item.orgLevel,
                        dimensionId:item.dimensionId
                    })
                })
            }
            return list
        },
        // 按键值对排序
        sorts_fun(e) {
            return function(a, b) {
                var v1 = a[e];
                var v2 = b[e];
                return v2 - v1;
            };
        }
    }
        
}