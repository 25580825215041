<!--
使用方法：
该组件分为两个部分：

1、上边工具栏部分:
    1.0、最左边返回按钮
        有返回按钮事件:$emit('backClick')

    1.1、左边title部分 #title
        直接在插槽内部写上文字即可

    1.2、左边搜索框部分 #toolsbarLeft
        若搜索内容需要向左对齐，可以直接在插槽内部写代码

    1.3、右边搜索框部分 #toolsbarRight
        若搜索内容需要向右对齐，可以直接插槽内部写代码，高度超过80px会显示 鼠标悬停显示更多选项

    1.4、面包屑导航栏部分 #toolsbarNav
        需要传数组进来navList，如果navList为空会隐藏,
        有点击触发事件 $emit('clickBar',item,index) 返回当前点击的项以及索引

    1.5、最右手边显示更多按钮以及右边隐藏伸缩框 #moreToolsRight
        需要传值进来，传moreTools对象

2、下边内容区域#contentArea
-->

<template>
  <div>
    <!--工具栏-->
    <div id="toolbar" ref="toolbar">
      <div id="toolsbarLeft" ref="toolsbarLeft">
        <!--标题-->
        <div @click="backClick" style="font-weight:bold;font-size:23px;cursor:pointer;color:#ed4014;" class="liefeng-icon liefeng-icon-direction-left" v-show="isBack" ></div>
        <div  style="font-size:23px;" class="liefeng-icon liefeng-icon-dynamic-filling" v-show="!isBack&&titleShow" ></div>
        <div
          style="
            margin-top: 5px;
            padding: 0 6px;
            font-size: 16px;
            margin-right: 50px;
            white-space: nowrap;
            cursor:pointer;
          "
          v-show="titleShow"
          ref="title"
          @click="backClick"
        >
          <slot name="title"></slot>
        </div>

        <slot name="toolsbarLeft">
          <!--靠左工具-->
        </slot>
      </div>
      
      <div id="toolsbarNav" class="toolsbarNav" ref="toolsbarNav" >
        <div :class="index == navList.length - 1 ? 'toolsbarNavItem toolsbarNavActive' :'toolsbarNavItem'" v-for="(item,index) in navList" :key="item + index"><div class="activeName" @click="clickBar(item,index)">{{item.name}} </div><div class="iconNav" v-if="index != navList.length - 1">{{'/'}}</div></div>
      </div>
      
      <div id="toolsbarRight" class="toolsbarRight" ref="toolsbarRight">
        <slot name="toolsbarRight">
          <!--靠右工具-->
        </slot>
      </div>
       <div id="toolsbarMove" v-if="showMoveTips">鼠标悬停显示更多选项</div>
      <div id="moreRight" v-if="moreTools.moreToolsShow">
        <Button
          :type="moreTools.type"
          :icon="moreTools.icon"
          @click="selectQuery = true"
          class="moreRight-button"
          >{{ moreTools.moreToolsName }}</Button
        >
      </div>
       <Drawer
          :title="moreTools.moreToolsName"
          v-model="selectQuery"
          :placement="moreTools.placement"
        > <!--更多工具弹窗：可自定义按钮，执行this.$refs.liefengContent.showMoreTools();-->
          <slot name="moreToolsRight"></slot>
          
        </Drawer>
    </div>

    <!--编辑区-->
    <div id="contentarea" ref="contentarea">
      <slot name="contentArea"> </slot>
    </div>
  </div>
</template>
<script>
import "/public/fontlibs/liefengiconfont.css";
export default {
  props: {
    isBack:{type: Boolean,default:false},//显示返回按钮，执行backClick事件，父级@backClick传入
    moreTools: {
      type: Object,
        default: {
          moreToolsShow: false,//是否显示侧窗按钮
          moreToolsName: "更多",//按钮名称
          icon: "- liefeng-icon liefeng-icon-drag",//按钮图标
          type:"default",//按钮样式
          placement: "right"
      },
      moreStyle: {
        type: Object,
        default: {
              height: 'calc(100% - 55px)',
              overflow: 'auto',
              paddingBottom: '53px',
              position: 'static',
            }
      }, 
  },
  navList:{
      type:Array,
      default: () =>{
        return []
      }
    }
  },
  data() {
    return {
      titleShow: false,
      selectQuery: false,
      showMoveTips:false,
    };
  },
  mounted() {
    if (this.$refs.title.innerText != "") {
      this.titleShow = true;
    }
    var that=this;
    this.$(document).on("mouseover","#toolsbarRight",function(){
    if($("#toolsbarRight")[0].scrollHeight>80){
      $("#toolsbarRight").removeClass("toolsbarRight").addClass("toolsbarRightFixed");
    }
    });
    this.$(document).on("click","#toolsbarRight",function(e){
     e.stopPropagation();
    });
    this.$(document).on("click",function(){
      $("#toolsbarRight").removeClass("toolsbarRightFixed").addClass("toolsbarRight");
    });
    var resize=function(){
     if($("#toolsbarRight")[0].scrollHeight>80){
       that.showMoveTips=true;
     }else{
        that.showMoveTips=false;
     }
    };
    resize();
    this.$(window).resize(resize);
  },
  methods:{
      showMoreTools(){
          this.selectQuery=true;
      }
      ,
      backClick(){
        if(this.navList && this.navList.length > 0){
          if(this.navList.length == 2){
            this.$router.push(this.navList[0].router)
            return
          }
        }
        this.$emit("backClick");
      },
      clickBar(item,index){
        if(index == this.navList.length - 1 && item.router == this.$route.path) return
        if(index == 0) {
          this.$router.push(`${item.router}?router=${JSON.stringify(this.navList)}`)
          this.navList = []
          return
        }else{
          this.$emit('clickBar',item,index)
        }
        
      }
  }
};
</script>

<style scoped>
#toolbar {
  min-height: 50px;
  background: #fafafa;
  display: flex;
  top: 0;
  position: fixed;
  min-width: 1000px;
  width: 100%;
  box-shadow: 0 0 1px 1px #ddd;
  z-index: 2;
}
#toolsbarLeft {
  margin: 10px;
  float: left;
  display: flex;
  justify-content: flex-start;
}

#toolsbarLeft > div > .bread {
  margin-left: 74px;
}
.toolsbarNav{
  width: auto !important;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  padding-top: 5px;
}
.toolsbarNavItem{
  justify-content: flex-start;
  font-size: 14px;
  color: #999;
  user-select: none; 
  display: flex;
}
.toolsbarNavActive{
  color: #515a6e;
  font-weight: 700;
}
.activeName{
  cursor: pointer;
}
.iconNav{
  color: #dcdee2;
  margin: 0 8px;
}
.toolsbarRight {
  margin: 10px;
  display: flex;
  justify-content: flex-end;
  width:auto !important;
  height: 40px;
  overflow: hidden;
  transition: 0.3s all;
  flex-wrap: wrap;
  margin-left:auto
}
#toolsbarMove{
  position: fixed;
  top:45px;
  left:50%;
  font-size: 12px;
  padding:2px 10px;
  color:#fff;
  background: #c5c8ce;
  text-align: center;
  border-radius: 10px;
    z-index: 100;
}
.toolsbarRightFixed {
  transition: 0.3s all;
  position: fixed;
  top:0;right:0;
  z-index: 111;
  margin: 0 10px;
  padding:10px;
  display: flex;
  justify-content: flex-end;
  width:calc(100% - 150px - 40px) !important;
  background: #e8eaec;
  border: 1px solid #c5c8ce;
  border-top: 0px solid #e8eaec;
  box-shadow: 0px 2px 10px #ccc;
  border-radius: 0 0 10px 10px;
  flex-wrap: wrap;
}
#toolsbarRight button,#toolsbarRight input,#toolsbarRight div,
#toolsbarLeft button {
  margin: 0 5px 10px 0;
}

#moreRight {
  float: right;
  margin: 10px;
}
 #moreRight button{
    padding:0 8px;
  }

.moreRight-btn {
  border-radius: 50px;
}

#contentarea {
  top: calc(55px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 10px 10px 15px;
  line-height: 1.5em;
  overflow: auto;
  z-index: 1;
}
.tooldiv {
  line-height: 30px;
  text-align: right;
  white-space: nowrap;
}
</style>
